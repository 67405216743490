import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  effect,
  inject,
  Injector,
  input,
  signal,
  untracked,
  ViewEncapsulation,
  type OnInit,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { RouterLink } from '@angular/router';
import { isEqual } from 'lodash-es';

import { CosGlobalMenuComponent } from '@cosmos/components/global-header';
import type { MenuItem, NavigationItem } from '@cosmos/types-layout';
import { coerceBooleanAttribute } from '@cosmos/util-coercion';
import {
  CosmosTranslocoService,
  CosmosUtilTranslationsModule,
  LanguageScope,
} from '@cosmos/util-translations';
import {
  assembleDesktopItems,
  assembleMobileItems,
  type NavigationMenuOptions,
} from '@customer-portal/common/feature-navigation-menu';

import { CustomerPortalHeaderLoaderComponent } from './header-loader.component';
import { HeaderLocalState } from './header.local-state';

@Component({
  selector: 'customer-portal-new-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  // eslint-disable-next-line @nx/workspace/no-encapsulation-none-style-bleeding
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'customer-portal-header',
    '[class.customer-portal-header--ready]': 'state.isReady()',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [HeaderLocalState],
  imports: [
    RouterLink,
    CosmosUtilTranslationsModule,
    CosGlobalMenuComponent,
    CustomerPortalHeaderLoaderComponent,
  ],
})
export class CustomerPortalNewHeaderComponent implements OnInit {
  readonly landingMode = input(false, { transform: coerceBooleanAttribute });

  readonly navItemsDesktop = signal<NavigationItem[]>([]);
  readonly navItemsMobile = signal<MenuItem[]>([]);

  private readonly _injector = inject(Injector);
  private readonly _cdRef = inject(ChangeDetectorRef);
  private readonly _translocoService = inject(CosmosTranslocoService);

  readonly state = inject(HeaderLocalState);

  readonly logoAsLink = computed(
    () => !this.landingMode() && this.state.presentation()
  );

  private readonly _translationIsReady = toSignal(
    this._translocoService.ensureScopesAreLoaded$([
      LanguageScope.CustomerPortalCommon,
    ])
  );

  readonly isReady = computed(
    () => this.state.isReady() && this._translationIsReady()
  );

  readonly meuOptions = computed(
    (): NavigationMenuOptions => ({
      project: this.state.project()!,
      presentation: this.state.presentation()!,
      userIsCustomer: this.state.userIsCustomer(),
      cartCount: this.state.cartCount(),
      quoteCount: this.state.quoteCount(),
      orderCount: this.state.orderCount(),
      invoiceCount: this.state.invoiceCount(),
      proofCount: this.state.proofCount(),
      presentationExpired: this.state.presentationExpired(),
    }),
    {
      equal: isEqual,
    }
  );

  readonly logoLinkUrl = computed(() => {
    return [
      '/projects',
      this.state.project()?.Id,
      'presentations',
      this.state.presentation()?.Id,
    ];
  });

  ngOnInit(): void {
    effect(
      () => {
        if (!this.isReady()) {
          return;
        }
        const menuOptions = this.meuOptions();
        untracked(() => {
          this.updateMenuItems(menuOptions);
        });
      },
      { injector: this._injector }
    );
  }

  updateMenuItems(menuOptions: NavigationMenuOptions = this.meuOptions()) {
    this.navItemsDesktop.set(
      // Remove icons and badges from desktop items
      assembleDesktopItems(menuOptions).map((item) => ({
        ...item,
        icon: undefined,
        badge: undefined,
      }))
    );
    this.navItemsMobile.set(
      // Remove badges from mobile items
      assembleMobileItems(menuOptions).map((item) => ({
        ...item,
        badge: undefined,
      }))
    );
    this._cdRef.detectChanges();
  }
}
