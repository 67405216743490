const ACTION_SCOPE = '[ESP Customer Portal Preview Encore]';

export namespace CustomerPortalIframeEncoreStateActions {
  export class Init {
    static readonly type = `${ACTION_SCOPE} Init`;
  }

  export class HeightChanged {
    static readonly type = `${ACTION_SCOPE} HeightChanged`;

    constructor(readonly heightPx: number) {}
  }
}
