import { DOCUMENT } from '@angular/common';
import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  type CanActivateFn,
} from '@angular/router';
import { Store } from '@ngxs/store';
import { firstValueFrom } from 'rxjs';

import { getParamsObject } from '@cosmos/core';
import { ToastActions } from '@cosmos/types-notification-and-toast';
import { LanguageScope } from '@cosmos/util-translations';
import { CustomerPortalAuthActions } from '@customer-portal/auth/data-access-auth';
import {
  CustomerPortalPresentationActions,
  CustomerPortalPresentationQueries,
} from '@customer-portal/presentations/data-access-presentations';

const languageScope = LanguageScope.CustomerPortalAuth;

function getTranslationKey(key: string) {
  return `${languageScope}.data-access-customer-portal-access-guard.legacy-url-handler.${key}`;
}

// TODO(portal-url-migration): remove once we don't support legacy presentations URL.
/**
 * This guard is used to handle legacy URL which starts with `/presentations/:presentationId`.
 * and then redirect user to `/projects/:projectId/presentations/:presentationId` URL.
 *
 */
export const customerPortalLegacyUrlRedirectHandler: CanActivateFn =
  async function (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const document = inject(DOCUMENT);
    const store = inject(Store);
    const router = inject(Router);
    const presentationId = route.paramMap.get('presentationId')!;
    const params =
      getParamsObject(route.queryParamMap, {
        optional: ['accessCode', 'settings', 'token', 'postPayment'],
      })! || {};
    const accessCode = params.accessCode!;
    const token = params.postPayment ? undefined : params.token;
    const settings = params.settings;

    // There must be token defined on params either access code.
    // Otherwise, we can't proceed with authentication.
    if (!token && !accessCode) {
      store.dispatch(
        new ToastActions.Show({
          title: getTranslationKey('missing-access-code-error-title'),
          body: getTranslationKey('missing-access-code-error-body'),
          type: 'error',
          languageScope,
        })
      );
      // Prevent any other code from being executed, the routing should go into a failed state.
      throw new Error(
        'There is no token or access code defined on URL params.'
      );
    }

    store.dispatch(
      new ToastActions.Show({
        title: '',
        body: getTranslationKey('url-redirection-body'),
        type: 'confirm',
        languageScope,
      })
    );

    await firstValueFrom(
      store.dispatch(
        token
          ? new CustomerPortalAuthActions.LoginSuccess({
              access_token: token,
            })
          : // We shouldn't be checking if the user is already logged in since we're storing the
            // session token in `sessionStorage` which is cleaned up when the user leaves the page,
            // no matter if the page is bookmarked or not.
            new CustomerPortalAuthActions.LoginWithAccessCode({
              accessCode,
              presentationId,
            })
      )
    );

    // At this moment we already have the user authenticated,
    // now load presentation to get the associated project id.
    await firstValueFrom(
      store.dispatch(
        // TODO: we should have a cheaper way to get project id from presentation id.
        new CustomerPortalPresentationActions.GetPresentation({
          presentationId,
          // no need to load quote at this moment cause we redirect user
          shouldLoadQuote: false,
        })
      )
    );
    const presentation = store.selectSnapshot(
      CustomerPortalPresentationQueries.getPresentation
    )!;

    const currentUrlTree = router.parseUrl(state.url);
    currentUrlTree.queryParams = {};

    const url = new URL(document.location.href);
    // `toString()` will give us a string with a slash at the beginning, for instance:
    // `/presentations/500000341/products`.
    // `slice(1)` is used to remove the slash so it becomes `presentations/500000341/products`.
    url.pathname = `/projects/${presentation.ProjectId}/${currentUrlTree
      .toString()
      .slice(1)}`;

    // We don't need token and access code anymore.
    url.searchParams.delete('token');
    url.searchParams.delete('accessCode');

    if (settings) {
      url.searchParams.set('settings', settings);
    }

    // Finally redirect the user to the new URL.
    const newPath = url.pathname + url.search;
    return router.parseUrl(newPath);
  };
