import {
  ChangeDetectionStrategy,
  Component,
  inject,
  provideAppInitializer,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';

import { CosButtonComponent } from '@cosmos/components/button';
import { CosFeatureFlagsToolbarControlsSet } from '@cosmos/feature-flags-toolbar';
import { DialogService } from '@cosmos/ui-dialog';
import { injectDestroyRef } from '@cosmos/util-common';
import { selectPresentationTemplate } from '@customer-portal/presentations/data-access-presentations';

import { configViewerDialogDef } from './config-viewer-dialog';

@Component({
  selector: 'customer-portal-feature-flags-template-select',
  template: `
    <div class="icon">
      <i class="fa fa-swatchbook"></i>
    </div>
    <div class="label space-x-2">
      <button
        cos-button
        mod="flat"
        color="primary"
        size="sm"
        postfixIcon="fa-solid fa-sliders"
        aria-label="Open Config Viewer"
        (click)="openConfigDialog()"
      >
        Show Template Config
      </button>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [FormsModule, CosButtonComponent],
})
export class FeatureFlagsTemplateSelectComponent {
  readonly template = selectPresentationTemplate();
  private readonly _dialogService = inject(DialogService);
  private readonly _destroyRef = injectDestroyRef();

  protected openConfigDialog() {
    this._dialogService
      .open(configViewerDialogDef, {
        template: this.template(),
      })
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe();
  }
}

export function withPresentationTemplateSelect() {
  return provideAppInitializer(() => {
    const controls = inject(CosFeatureFlagsToolbarControlsSet);
    controls.add(FeatureFlagsTemplateSelectComponent);
  });
}
