import type { Signal } from '@angular/core';
import { select } from '@ngxs/store';

import type { PresentationBannerData } from '@esp/presentations/page-modules/types-common';

import { CustomerPortalPresentationQueries } from '../queries/presentation.queries';

export function selectPresentationTemplate() {
  return select(CustomerPortalPresentationQueries.getTemplate);
}

export function selectIsModernTemplate() {
  return select(CustomerPortalPresentationQueries.isModernTemplate);
}

export function injectTemplatePageBannerData(): Signal<PresentationBannerData | null> {
  return select(CustomerPortalPresentationQueries.getPageBannerData);
}
