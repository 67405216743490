import { inject, provideAppInitializer } from '@angular/core';
import { Store } from '@ngxs/store';

import { CustomerPortalProjectDetailsActions } from '@customer-portal/projects/data-access/store';
import { CustomerPortalIframeStateActions } from '@esp/presentations/types-customer-portal-iframe-actions';

import {
  getMockedDesign,
  getMockedDistributor,
  getMockedPresentation,
  getMockedProject,
} from './mocks';
import { PreviewService } from './preview.service';

export const provideMockPreviewData = () =>
  provideAppInitializer(() => {
    // We're looking for the query param to determine whether data should be mocked.
    // This approach is fine because mocked data is only used within statically displayed pages in previews.
    // This means query param cannot be accidentally dropped during navigation leading to a broken state w\o mocks.
    const useMockData = inject(PreviewService).useMockProjectAndPresentation;

    if (useMockData) {
      const store = inject(Store);
      store.dispatch([
        new CustomerPortalIframeStateActions.PresentationChanged(
          getMockedPresentation()
        ),
        new CustomerPortalIframeStateActions.ProjectChanged(getMockedProject()),
        new CustomerPortalIframeStateActions.DistributorChanged(
          getMockedDistributor()
        ),
        new CustomerPortalIframeStateActions.PresentationDesignChanged(
          getMockedDesign()
        ),
        new CustomerPortalProjectDetailsActions.ResetDataForTemplatePreview(),
      ]);
    }
  });
