import { DialogOverlayWidth } from '@cosmos/components/dialog';
import { createDialogDef } from '@cosmos/ui-dialog';

import type {
  ConfigViewerDialogData,
  ConfigViewerDialogResult,
} from './models';

export const configViewerDialogDef = createDialogDef<
  ConfigViewerDialogData,
  ConfigViewerDialogResult
>({
  load: () => import(`./config-viewer.dialog`),
  defaultConfig: {
    width: DialogOverlayWidth.MD,
  },
});
