import { computed, Injectable } from '@angular/core';
import { select } from '@ngxs/store';

import {
  CustomerPortalDistributorQueries,
  CustomerPortalPresentationQueries,
} from '@customer-portal/presentations/data-access-presentations';
import { CustomerPortalProjectQueries } from '@customer-portal/projects/data-access/store';

@Injectable()
export class FooterLocalState {
  readonly project = select(CustomerPortalProjectQueries.getProject);
  readonly design = select(CustomerPortalDistributorQueries.getDesign);
  readonly distributor = select(CustomerPortalDistributorQueries.getProfile);
  readonly presentation = select(
    CustomerPortalPresentationQueries.getPresentation
  );
  readonly template = select(CustomerPortalPresentationQueries.getTemplate);
  readonly templateFooter = select(
    CustomerPortalPresentationQueries.getTemplateFooter
  );

  readonly footerLogoUrl = computed(() => {
    return this.templateFooter()?.Image?.FileUrl ?? this.design()?.ImageUrl;
  });

  readonly footerCustomText = computed(() => {
    return this.templateFooter()?.Text;
  });

  readonly hasLoaded = computed(() => {
    return (
      this.presentation() != null &&
      this.template() != null &&
      this.distributor() != null &&
      this.design() != null
    );
  });
}
