import { inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { getActionTypeFromInstance, Store } from '@ngxs/store';
import { filter, map } from 'rxjs';

import type { DataBus } from './base-data-bus';
import type { DataBusMessage } from './types';

interface SerializedAction<T extends object> {
  actionValue: T;
  actionType: string | undefined;
}

/**
 * Wrapper class for NGXS actions.
 * Should be used if you want to emit actions into the iframe.
 */
export class DataBusStateActionMessage<T extends object>
  implements DataBusMessage
{
  static readonly TYPE_KEY = 'DATA_BUS_STATE_ACTION_MESSAGE';
  readonly type = DataBusStateActionMessage.TYPE_KEY;

  readonly payload: SerializedAction<T>;
  constructor(action: T) {
    this.payload = {
      actionType: getActionTypeFromInstance(action),
      actionValue: action,
    };
  }
}

export function isStateActionMessage(
  message: DataBusMessage
): message is DataBusStateActionMessage<object> {
  return message.type === DataBusStateActionMessage.TYPE_KEY;
}

export function actionMessageToAction<T extends object>(
  action: DataBusStateActionMessage<T>
): T & { type?: string } {
  return {
    ...action.payload.actionValue,
    type:
      (<{ type?: string }>action.payload.actionValue)['type'] ??
      action.payload.actionType,
  };
}

/**
 * Subscribes to the data bus events and re-emits them to the NGXS Store
 * @param sourceDataBus data bus to listen to
 *
 * @usage
 * ```typescript
 * export class MyHostComponent {
 * ...
 * constructor() {
 *   // initStoreDispatcher is a util imported from "@cosmos/util-data-bus"
 *   // this allows to re-emit values from the DataBus into the state management
 *   // it should be instantiated in the component that receives messages
 *   initStoreDispatcher(this._dataBus);
 * }
 *...
 * notifyHeightChanged(): void {
 *     // this is a plain ngxs action we usually work with
 *     const action = new WebsitesPreviewEncoreStateActions.HeightChanged(
 *         e.contentRect.height
 *     );
 *     this._dataBus.dispatch(
 *       // wrapping NGXS action in "DataBusStateActionMessage" class in order to pass it into the Iframe window with preserved metadata (the static "type" property)
 *       new DataBusStateActionMessage(action)
 *     );
 * }
 * ```
 */
export function initStoreDispatcher(sourceDataBus: DataBus) {
  const store = inject(Store);
  sourceDataBus.onMessage$
    .pipe(
      filter(isStateActionMessage),
      map(actionMessageToAction),
      // destroy ref is not needed, we're in the injection context
      takeUntilDestroyed()
    )
    .subscribe((action) => store.dispatch(action));
}
