import { DOCUMENT } from '@angular/common';
import { inject, Injectable, Injector } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { select } from '@ngxs/store';
import { distinctUntilChanged } from 'rxjs';

import { injectDestroyRef } from '@cosmos/util-common';
import { CustomerPortalPresentationQueries } from '@customer-portal/presentations/data-access-presentations';
import type { TemplateVariables } from '@website-modules/types-common';

const DOCUMENT_WITH_TEMPLATE_VARIABLES_CLASS_NAME =
  'esp-presentation-with-template-variables';
const DEFAULT_PRIMARY_COLOR = '#6A7281';

@Injectable({
  providedIn: 'root',
})
export class PresentationTemplateVariablesService {
  private readonly _templateVars = select(
    CustomerPortalPresentationQueries.getTemplateVariables
  );
  private readonly _document = inject(DOCUMENT);
  private readonly _injector = inject(Injector);
  private readonly _destroyRef = injectDestroyRef();

  init() {
    this._listenForTemplateChangesAndUpdateCssVars();
    this._destroyRef.onDestroy(() => {
      this._removeCssVarsFromDocument();
    });
  }

  private _listenForTemplateChangesAndUpdateCssVars() {
    toObservable(this._templateVars, { injector: this._injector })
      .pipe(distinctUntilChanged(), takeUntilDestroyed(this._destroyRef))
      .subscribe((templateVariables) => {
        if (!templateVariables) {
          this._removeCssVarsFromDocument();
          return;
        }
        this._setCssVarsInDocument();
      });
  }

  private async _getTemplateVariables() {
    const templateVariables = this._templateVars();
    return {
      ...templateVariables,
      // download the default primary color if it's not provided
      PrimaryColor: templateVariables?.PrimaryColor ?? DEFAULT_PRIMARY_COLOR,
    } as TemplateVariables;
  }

  private async _setCssVarsInDocument() {
    const document = this._document;
    const templateVariables = await this._getTemplateVariables();

    document.documentElement.style.setProperty(
      `--template-primary-color`,
      templateVariables.PrimaryColor!
    );
    document.documentElement.style.setProperty(
      `--template-secondary-color`,
      templateVariables.SecondaryColor!
    );
    document.documentElement.style.setProperty(
      `--template-tertiary-color`,
      templateVariables.TertiaryColor!
    );
    document.documentElement.style.setProperty(
      `--template-highlight-color`,
      templateVariables.HighlightColor!
    );

    document.documentElement.classList.add(
      DOCUMENT_WITH_TEMPLATE_VARIABLES_CLASS_NAME
    );
  }

  private _removeCssVarsFromDocument() {
    const document = this._document;

    document.documentElement.style.removeProperty(`--template-primary-color`);
    document.documentElement.style.removeProperty(`--template-secondary-color`);
    document.documentElement.style.removeProperty(`--template-tertiary-color`);
    document.documentElement.style.removeProperty(`--template-highlight-color`);

    document.documentElement.classList.remove(
      DOCUMENT_WITH_TEMPLATE_VARIABLES_CLASS_NAME
    );
  }
}
