import {
  ThirdPartyRecordOption,
  type Distributor,
  type DistributorAddress,
  type DistributorDesign,
} from '@cosmos/types-common';
import type {
  Presentation,
  PresentationStatus,
} from '@esp/presentations/types';
import { ProjectStepName, type Project } from '@esp/projects/types';

export const getMockedProject = (): Project => {
  const project: Project = {
    Id: -1,
    Name: 'Presentation Name',
    ProjectEmail: 'MQNO1MVCI',
    Customer: {
      PrimaryBrandColor: '#007dab ',
      LogoImageUrl:
        '/assets/presentation-templates/presentation-template-customer-logo-placeholder.svg',
      Types: ['Customer'],
      Id: 513997483,
      Name: 'Customer Name',
      PrimaryPhoneNumber: '(866) 555-1232',
      PrimaryEmailAddress: 'customer.name@email.com',
      IconImageUrl:
        '/assets/presentation-templates/presentation-template-customer-logo-placeholder.svg',
      OwnerId: 557456,
    },
    EventType: 'Charity/Non Profits',
    Budget: 0,
    CurrencyTypeCode: 'USD',
    NumberOfAssignees: 0,
    StatusStep: 0,
    StatusName: ProjectStepName.NegotiatingAndPitching,
    CreateDate: '2025-02-19T21:50:09.003Z' as unknown as Date,
    Owner: {
      Id: 557456,
      Name: 'John Doe',
    },
    Statuses: [
      {
        Name: ProjectStepName.NegotiatingAndPitching,
        Step: 0,
        CreateDate: new Date(
          new Date().getFullYear(),
          8,
          7
        ) as unknown as string,
      },
    ],
    TenantId: 500049971,
    OwnerId: 557456,
    AccessLevel: 'Everyone',
    Access: [
      {
        AccessLevel: 'Everyone',
        EntityId: 0,
        AccessType: 'Read',
      },
    ],
    IsVisible: true,
    IsEditable: true,
    Collaborators: [
      {
        Id: 557456,
        Name: 'John Doe',
        Email: 'john.doe@gmail.com',
        ImageUrl:
          '/assets/presentation-templates/presentation-template-customer-logo-placeholder.svg',
        IsTeam: false,
        Role: 'Owner',
      },
    ],
  };
  return project;
};

export const getMockedPresentation = (): Presentation => {
  // TODO: this should be ASI.Contracts.Venus.PresentationView
  return {
    Id: -1,
    ProjectId: -1,
    PresentationTemplateId: null,
    IsDeleted: false,
    Customer: {
      Name: 'Customer Name',
      IconMediaLink: {
        Id: 0,
        MediaId: 5799982,
        FileUrl:
          '/assets/presentation-templates/presentation-template-customer-logo-placeholder.svg',
        DownloadFileUrl:
          '/assets/presentation-templates/presentation-template-customer-logo-placeholder.svg',
        IsVisible: false,
        FileSize: 0,
      },
      OwnerId: 557456,
      Id: 513997483,
      PrimaryPhoneNumber: '(866) 555-1232',
      PrimaryEmailAddress: 'customer.name@email.com',
    },
    Settings: {
      ShowProductColors: true,
      ShowProductSizes: true,
      ShowProductShape: true,
      ShowProductMaterial: true,
      ShowProductCPN: false,
      ShowProductImprintMethods: true,
      ShowImprintOptions: false,
      ShowProductPricing: true,
      ShowProductPriceGrids: false,
      ShowProductPriceRanges: false,
      ShowProductAdditionalCharges: false,
      ShowProductDiscount: false,
      ShowProductAlwaysFreeSetup: false,
      ShowProductCanOrderLessThanMinimum: false,
      ShowProductAdditionalOptions: true,
    },
    AllowProductVariants: true,
    ShowSignature: false,
    CreateDate: '2025-02-19T21:50:09.59Z',
    UpdateDate: '2025-02-20T14:05:46.273Z',
    ExpirationDate: new Date(new Date().getFullYear(), 5, 1),
    Status: 'PostShare' as PresentationStatus,
    LastViewDate: '2025-02-20T14:05:45Z',
    SharedDate: new Date(new Date().getFullYear(), 8, 7),
    NumberOfProductsDisliked: 0,
    NumberOfProductsQuoted: 0,
    // eslint-disable-next-line no-irregular-whitespace
    // Products array must include product details model /api/presentations/{presentationId}/products/{presentationProductId}
    // so it can be used in the presentation product page
    Products: [
      {
        ProductId: -1,
        Id: -1,
        IsVisible: true,
        ShowMinMaxRange: true,
        IsCustomPriceRange: false,
        AdjustmentType: 'profit-margin',
        Adjustment: 0,
        RoundPricesToTwoDecimal: true,
        Sequence: 1,
        Supplier: {
          ExternalId: 6280009,
          OwnerId: 0,
          Id: 500283274,
        },
        Status: 'Viewed',
        StatusDate: '2025-03-11T16:25:33.77Z',
        Like: 'None',
        OriginalPriceGrids: [],
        Name: "Men's Performance T-Shirt",
        Description:
          'Been there, done that, got the t-shirt! Spotlight your brand for all to see with these cool and colorful T-Shirts. These unisex shorts are made of 3.8 ounce 130GSM polyester interlock for fabric that wicks away moisture and provides UV protection for the wearer. Design features include a heat-sealed label and cationic dye coloration process. Choose from a wide range of shades and styles and add your school, sports team, organizational or company logo, emblem or message to customize. Sizes small to 3XL.',
        Summary: 'Team365(R) Zone Performance polyester unisex t-shirts.',
        Number: 'TT11',
        DefaultMedia: {
          IsVisible: true,
          Sequence: 0,
          Id: 32922079,
          Type: 'IM',
          Url: 'https://new-media-asicdn.azureedge.net/images/jpgo/32920000/32922079.jpg',
          IsPrimary: true,
          IsVirtualSampleReady: true,
          Attributes: [],
          IsCustom: false,
        },
        Media: [
          {
            IsVisible: true,
            Sequence: 0,
            Id: 32922079,
            Type: 'IM',
            Url: 'https://new-media-asicdn.azureedge.net/images/jpgo/32920000/32922079.jpg',
            IsPrimary: true,
            IsVirtualSampleReady: true,
            Attributes: [],
            IsCustom: false,
          },
          {
            IsVisible: true,
            Sequence: 1,
            Id: 32922076,
            Type: 'IM',
            Url: 'https://new-media-asicdn.azureedge.net/images/jpgo/32920000/32922076.jpg',
            IsPrimary: false,
            IsVirtualSampleReady: true,
            Attributes: [],
            IsCustom: false,
          },
          {
            IsVisible: true,
            Sequence: 2,
            Id: 32922081,
            Type: 'IM',
            Url: 'https://new-media-asicdn.azureedge.net/images/jpgo/32920000/32922081.jpg',
            IsPrimary: false,
            IsVirtualSampleReady: true,
            Attributes: [],
            IsCustom: false,
          },
          {
            IsVisible: true,
            Sequence: 3,
            Id: 32922082,
            Type: 'IM',
            Url: 'https://new-media-asicdn.azureedge.net/images/jpgo/32920000/32922082.jpg',
            IsPrimary: false,
            IsVirtualSampleReady: true,
            Attributes: [],
            IsCustom: false,
          },
          {
            IsVisible: true,
            Sequence: 4,
            Id: 32922083,
            Type: 'IM',
            Url: 'https://new-media-asicdn.azureedge.net/images/jpgo/32920000/32922083.jpg',
            IsPrimary: false,
            IsVirtualSampleReady: true,
            Attributes: [806526839],
            IsCustom: false,
          },
          {
            IsVisible: true,
            Sequence: 5,
            Id: 32922084,
            Type: 'IM',
            Url: 'https://new-media-asicdn.azureedge.net/images/jpgo/32920000/32922084.jpg',
            IsPrimary: false,
            IsVirtualSampleReady: true,
            Attributes: [],
            IsCustom: false,
          },
          {
            IsVisible: true,
            Sequence: 6,
            Id: 33081599,
            Type: 'IM',
            Url: 'https://new-media-asicdn.azureedge.net/images/jpgo/33080000/33081599.jpg',
            IsPrimary: false,
            IsVirtualSampleReady: true,
            Attributes: [],
            IsCustom: false,
          },
          {
            IsVisible: true,
            Sequence: 7,
            Id: 33081600,
            Type: 'IM',
            Url: 'https://new-media-asicdn.azureedge.net/images/jpgo/33080000/33081600.jpg',
            IsPrimary: false,
            IsVirtualSampleReady: true,
            Attributes: [813774924],
            IsCustom: false,
          },
          {
            IsVisible: true,
            Sequence: 8,
            Id: 36929757,
            Type: 'IM',
            Url: 'https://new-media-asicdn.azureedge.net/images/jpgo/36920000/36929757.jpg',
            IsPrimary: false,
            IsVirtualSampleReady: true,
            Attributes: [],
            IsCustom: false,
          },
          {
            IsVisible: true,
            Sequence: 10,
            Id: 36929759,
            Type: 'IM',
            Url: 'https://new-media-asicdn.azureedge.net/images/jpgo/36920000/36929759.jpg',
            IsPrimary: false,
            IsVirtualSampleReady: true,
            Attributes: [],
            IsCustom: false,
          },
          {
            IsVisible: true,
            Sequence: 11,
            Id: 36929760,
            Type: 'IM',
            Url: 'https://new-media-asicdn.azureedge.net/images/jpgo/36920000/36929760.jpg',
            IsPrimary: false,
            IsVirtualSampleReady: true,
            Attributes: [813774829],
            IsCustom: false,
          },
          {
            IsVisible: true,
            Sequence: 12,
            Id: 36929762,
            Type: 'IM',
            Url: 'https://new-media-asicdn.azureedge.net/images/jpgo/36920000/36929762.jpg',
            IsPrimary: false,
            IsVirtualSampleReady: true,
            Attributes: [],
            IsCustom: false,
          },
          {
            IsVisible: true,
            Sequence: 13,
            Id: 36929763,
            Type: 'IM',
            Url: 'https://new-media-asicdn.azureedge.net/images/jpgo/36920000/36929763.jpg',
            IsPrimary: false,
            IsVirtualSampleReady: true,
            Attributes: [],
            IsCustom: false,
          },
          {
            IsVisible: true,
            Sequence: 14,
            Id: 36929764,
            Type: 'IM',
            Url: 'https://new-media-asicdn.azureedge.net/images/jpgo/36920000/36929764.jpg',
            IsPrimary: false,
            IsVirtualSampleReady: true,
            Attributes: [],
            IsCustom: false,
          },
          {
            IsVisible: true,
            Sequence: 15,
            Id: 36929765,
            Type: 'IM',
            Url: 'https://new-media-asicdn.azureedge.net/images/jpgo/36920000/36929765.jpg',
            IsPrimary: false,
            IsVirtualSampleReady: true,
            Attributes: [],
            IsCustom: false,
          },
          {
            IsVisible: true,
            Sequence: 16,
            Id: 36929766,
            Type: 'IM',
            Url: 'https://new-media-asicdn.azureedge.net/images/jpgo/36920000/36929766.jpg',
            IsPrimary: false,
            IsVirtualSampleReady: true,
            Attributes: [],
            IsCustom: false,
          },
          {
            IsVisible: true,
            Sequence: 17,
            Id: 36929767,
            Type: 'IM',
            Url: 'https://new-media-asicdn.azureedge.net/images/jpgo/36920000/36929767.jpg',
            IsPrimary: false,
            IsVirtualSampleReady: true,
            Attributes: [],
            IsCustom: false,
          },
          {
            IsVisible: true,
            Sequence: 18,
            Id: 36929768,
            Type: 'IM',
            Url: 'https://new-media-asicdn.azureedge.net/images/jpgo/36920000/36929768.jpg',
            IsPrimary: false,
            IsVirtualSampleReady: true,
            Attributes: [],
            IsCustom: false,
          },
          {
            IsVisible: true,
            Sequence: 19,
            Id: 36929769,
            Type: 'IM',
            Url: 'https://new-media-asicdn.azureedge.net/images/jpgo/36920000/36929769.jpg',
            IsPrimary: false,
            IsVirtualSampleReady: true,
            Attributes: [],
            IsCustom: false,
          },
        ],
        Attributes: [],
        PriceGrids: [
          {
            IsVisible: true,
            Sequence: 0,
            Id: 847356436,
            Description:
              'SP CHARITY PINK,SP ATHLETIC GOLD,SPORT DARK NAVY,Black,SPORT DARK BROWN,SAFETY YELLOW,SPORT DRK M...',
            Prices: [
              {
                IsVisible: true,
                Sequence: 0,
                Price: 9,
                Cost: 4.5,
                DiscountCode: 'P',
                DiscountPercent: 0.5,
                CurrencyCode: 'USD',
                IsUndefined: false,
                Quantity: {
                  From: 1,
                  To: 2147483647,
                },
              },
            ],
            UsageLevel: 'NONE',
            Attributes: [],
          },
        ],
        Charges: [],
        Currencies: [
          {
            Symbol: '$',
            DecimalSeparator: '.',
            GroupSeparator: ',',
            Code: 'USD',
            Name: 'US Dollar',
          },
        ],
        LowestPrice: {
          IsVisible: true,
          Sequence: 0,
          Price: 9,
          Cost: 4.5,
          DiscountCode: 'P',
          DiscountPercent: 0.5,
          CurrencyCode: 'USD',
          IsUndefined: false,
          Quantity: {
            From: 1,
            To: 2147483647,
          },
        },
        HighestPrice: {
          IsVisible: true,
          Sequence: 0,
          Price: 9,
          Cost: 4.5,
          DiscountCode: 'P',
          DiscountPercent: 0.5,
          CurrencyCode: 'USD',
          IsUndefined: false,
          Quantity: {
            From: 1,
            To: 2147483647,
          },
        },
        UpdateDate: '2022-03-25T19:40:40.49Z',
        ExpirationDate: '0001-01-01T05:00:00Z',
        PublishDate: '2022-03-25T15:40:40.82Z',
        SKU: [
          {
            SKU: 'TT11:SPORT DRK MAROON:2XLarge',
            Values: [849828024],
          },
          {
            SKU: 'TT11:SPORT DARK NAVY:2XLarge',
            Values: [849828025],
          },
          {
            SKU: 'TT11:SPORT DARK BROWN:2XLarge',
            Values: [813774829],
          },
          {
            SKU: 'TT11:SP CHARITY PINK:2XLarge',
            Values: [849828026],
          },
          {
            SKU: 'TT11:SP ATHLETIC GOLD:2XLarge',
            Values: [849828027],
          },
          {
            SKU: 'TT11:SAFETY YELLOW:2XLarge',
            Values: [813774924],
          },
          {
            SKU: 'TT11:Black:2XLarge',
            Values: [806526839],
          },
        ],
        Settings: {
          ShowProductColors: true,
          ShowProductSizes: true,
          ShowProductShape: true,
          ShowProductMaterial: true,
          ShowProductCPN: true,
          ShowProductImprintMethods: true,
          ShowImprintOptions: false,
          ShowProductPricing: true,
          ShowProductPriceGrids: false,
          ShowProductPriceRanges: true,
          ShowProductAdditionalCharges: true,
          ShowProductDiscount: true,
          ShowProductAlwaysFreeSetup: false,
          ShowProductCanOrderLessThanMinimum: false,
          ShowProductAdditionalOptions: true,
        },
        ImprintColors: 'Standard',
        ImprintLocations:
          'LEFT CHEST, Left Sleeve Bicep, NAPE OF NECK, RIGHT CHEST, FULL BACK, FRONT CENTRE, LEFT BICEP, RIGHT BICEP',
        ImprintSizes: '4"H x 4"W, 16"H x 14"W',
      },
      {
        ProductId: -2,
        Id: -2,
        IsVisible: true,
        ShowMinMaxRange: true,
        IsCustomPriceRange: false,
        AdjustmentType: 'profit-margin',
        Adjustment: 0,
        RoundPricesToTwoDecimal: true,
        Sequence: 2,
        Supplier: {
          ExternalId: 6280009,
          OwnerId: 0,
          Id: 500283274,
        },
        Status: 'None',
        StatusDate: '0001-01-01T00:00:00Z',
        Like: 'None',
        OriginalPriceGrids: [],
        Name: 'Pen Stylus',
        Description:
          'Leave behind a good tip - like the stylus! This ABS plastic touch screen pen features a matte metallic sprayed barrel, solid black TPR grip, chrome plated trim, black ABS plastic clip, and a silicone touch screen stylus on top. With black medium point ink, this writing utensil is sure to stand out from the rest once imprinted with your logo or company name. From fundraisers to tradeshows, this must-have pen is great giveaway for your next event!',
        Summary:
          'ABS plastic touch screen pen with matte metallic sprayed barrel, solid black TPR grip, chrome plated trim, clip and black ink.',
        Number: 'P665',
        DefaultMedia: {
          IsVisible: true,
          Sequence: 1,
          Id: 43885355,
          Type: 'IM',
          Url: 'https://new-media-asicdn.azureedge.net/images/jpgo/43880000/43885355.jpg',
          IsPrimary: false,
          IsVirtualSampleReady: false,
          Attributes: [],
          IsCustom: false,
        },
        Media: [
          {
            IsVisible: true,
            Sequence: 1,
            Id: 43885355,
            Type: 'IM',
            Url: 'https://new-media-asicdn.azureedge.net/images/jpgo/43880000/43885355.jpg',
            IsPrimary: false,
            IsVirtualSampleReady: false,
            Attributes: [],
            IsCustom: false,
          },
          {
            IsVisible: true,
            Sequence: 2,
            Id: 35452174,
            Type: 'IM',
            Url: 'https://new-media-asicdn.azureedge.net/images/jpgo/35450000/35452174.jpg',
            IsPrimary: false,
            IsVirtualSampleReady: true,
            Attributes: [],
            IsCustom: false,
          },
          {
            IsVisible: true,
            Sequence: 3,
            Id: 35452175,
            Type: 'IM',
            Url: 'https://new-media-asicdn.azureedge.net/images/jpgo/35450000/35452175.jpg',
            IsPrimary: false,
            IsVirtualSampleReady: true,
            Attributes: [],
            IsCustom: false,
          },
          {
            IsVisible: true,
            Sequence: 5,
            Id: 43885353,
            Type: 'IM',
            Url: 'https://new-media-asicdn.azureedge.net/images/jpgo/43880000/43885353.jpg',
            IsPrimary: false,
            IsVirtualSampleReady: false,
            Attributes: [800980338],
            IsCustom: false,
          },
          {
            IsVisible: true,
            Sequence: 6,
            Id: 43885354,
            Type: 'IM',
            Url: 'https://new-media-asicdn.azureedge.net/images/jpgo/43880000/43885354.jpg',
            IsPrimary: false,
            IsVirtualSampleReady: false,
            Attributes: [],
            IsCustom: false,
          },
        ],
        Attributes: [],
        PriceGrids: [
          {
            IsVisible: true,
            Sequence: 0,
            Id: 848341370,
            Description: 'Purple,Pad Print',
            Prices: [
              {
                IsVisible: true,
                Sequence: 0,
                Price: 0.66,
                Cost: 0.396,
                DiscountCode: 'R',
                DiscountPercent: 0.4,
                CurrencyCode: 'USD',
                IsUndefined: false,
                Quantity: {
                  From: 250,
                  To: 599,
                },
              },
              {
                IsVisible: true,
                Sequence: 1,
                Price: 0.62,
                Cost: 0.372,
                DiscountCode: 'R',
                DiscountPercent: 0.4,
                CurrencyCode: 'USD',
                IsUndefined: false,
                Quantity: {
                  From: 600,
                  To: 1199,
                },
              },
              {
                IsVisible: true,
                Sequence: 2,
                Price: 0.58,
                Cost: 0.348,
                DiscountCode: 'R',
                DiscountPercent: 0.4,
                CurrencyCode: 'USD',
                IsUndefined: false,
                Quantity: {
                  From: 1200,
                  To: 2599,
                },
              },
              {
                IsVisible: true,
                Sequence: 3,
                Price: 0.54,
                Cost: 0.324,
                DiscountCode: 'R',
                DiscountPercent: 0.4,
                CurrencyCode: 'USD',
                IsUndefined: false,
                Quantity: {
                  From: 2600,
                  To: 5199,
                },
              },
              {
                IsVisible: true,
                Sequence: 4,
                Price: 0.5,
                Cost: 0.3,
                DiscountCode: 'R',
                DiscountPercent: 0.4,
                CurrencyCode: 'USD',
                IsUndefined: false,
                Quantity: {
                  From: 5200,
                  To: 2147483647,
                },
              },
            ],
            UsageLevel: 'NONE',
            Attributes: [],
          },
        ],
        Charges: [],
        Currencies: [
          {
            Symbol: '$',
            DecimalSeparator: '.',
            GroupSeparator: ',',
            Code: 'USD',
            Name: 'US Dollar',
          },
        ],
        LowestPrice: {
          IsVisible: true,
          Sequence: 4,
          Price: 0.5,
          Cost: 0.3,
          DiscountCode: 'R',
          DiscountPercent: 0.4,
          CurrencyCode: 'USD',
          IsUndefined: false,
          Quantity: {
            From: 5200,
            To: 2147483647,
          },
        },
        HighestPrice: {
          IsVisible: true,
          Sequence: 0,
          Price: 0.66,
          Cost: 0.396,
          DiscountCode: 'R',
          DiscountPercent: 0.4,
          CurrencyCode: 'USD',
          IsUndefined: false,
          Quantity: {
            From: 250,
            To: 599,
          },
        },
        UpdateDate: '2023-03-07T23:43:58.617Z',
        ExpirationDate: '0001-01-01T05:00:00Z',
        PublishDate: '2023-03-07T18:43:58.63Z',
        SKU: [
          {
            SKU: 'P665:Purple',
            Values: [800980338],
          },
        ],
        Settings: {
          ShowProductColors: true,
          ShowProductSizes: true,
          ShowProductShape: true,
          ShowProductMaterial: true,
          ShowProductCPN: true,
          ShowProductImprintMethods: true,
          ShowImprintOptions: false,
          ShowProductPricing: true,
          ShowProductPriceGrids: false,
          ShowProductPriceRanges: true,
          ShowProductAdditionalCharges: true,
          ShowProductDiscount: true,
          ShowProductAlwaysFreeSetup: false,
          ShowProductCanOrderLessThanMinimum: false,
          ShowProductAdditionalOptions: true,
        },
        ImprintColors:
          'Black, Blue-Navy - 282 C, Blue-Process - Process Blue C, Blue-Reflex - Reflex Blue C, Burgundy - 202 C, Gold (Metallic) - 874 C, Gray - 423 C, Gray - Cool Gray 4 C, Green-Dark - 343 C, Green-Lime - 375 C, Green-Medium - 347 C, Orange - 021 C, Pink-Hot - 225 C, Purple - 268 C, Red - 199 C, Red - 485 C, Silver (Metallic) - 877 C, Teal - 327 C, White, Yellow - 102 C, Yellow-Gold-Athletic - 123 C',
        ImprintLocations: 'Centered on Barrel, Opposite side of clip',
        ImprintSizes: '0.375"H x 1.5"W',
      },
      {
        ProductId: -3,
        Id: -3,
        IsVisible: true,
        ShowMinMaxRange: true,
        IsCustomPriceRange: false,
        AdjustmentType: 'profit-margin',
        Adjustment: 0,
        RoundPricesToTwoDecimal: true,
        Sequence: 3,
        Supplier: {
          ExternalId: 6280009,
          OwnerId: 0,
          Id: 500283274,
        },
        Status: 'None',
        StatusDate: '0001-01-01T00:00:00Z',
        Like: 'None',
        OriginalPriceGrids: [],
        Name: '15oz Campfire Ceramic Mug',
        Description:
          'Say cheers to a successful promotion when you add this 15 oz. campfire ceramic mug to it! The speckled ceramic mug is a campsite classic and offered in three product colors. It holds up to 15 oz. (443 mL) and measures 3.6" H x 4" diameter (not including handle) and 3.6" H x 5.25" W (including handle). Add your company\'s name, logo and advertising message before handing it out. This is a great addition to almost any marketing campaign, including those for camping grounds, hiking trails, wilderness stores and more! Handwash only. Do not microwave.',
        Summary:
          'Campfire ceramic stonewear mug that measures 3.6" H x 4" diameter (not including handle) and holds up to 15 oz.',
        Number: 'CM107',
        DefaultMedia: {
          IsVisible: true,
          Sequence: 1,
          Id: 41196445,
          Type: 'IM',
          Url: 'https://new-media-asicdn.azureedge.net/images/jpgo/41190000/41196445.jpg',
          IsPrimary: false,
          IsVirtualSampleReady: true,
          Attributes: [],
          IsCustom: false,
        },
        Media: [
          {
            IsVisible: true,
            Sequence: 1,
            Id: 41196445,
            Type: 'IM',
            Url: 'https://new-media-asicdn.azureedge.net/images/jpgo/41190000/41196445.jpg',
            IsPrimary: false,
            IsVirtualSampleReady: true,
            Attributes: [],
            IsCustom: false,
          },
          {
            IsVisible: true,
            Sequence: 2,
            Id: 41196396,
            Type: 'IM',
            Url: 'https://new-media-asicdn.azureedge.net/images/jpgo/41190000/41196396.jpg',
            IsPrimary: false,
            IsVirtualSampleReady: true,
            Attributes: [],
            IsCustom: false,
          },
          {
            IsVisible: true,
            Sequence: 3,
            Id: 35417667,
            Type: 'IM',
            Url: 'https://new-media-asicdn.azureedge.net/images/jpgo/35410000/35417667.jpg',
            IsPrimary: false,
            IsVirtualSampleReady: true,
            Attributes: [800989021],
            IsCustom: false,
          },
          {
            IsVisible: true,
            Sequence: 4,
            Id: 35417668,
            Type: 'IM',
            Url: 'https://new-media-asicdn.azureedge.net/images/jpgo/35410000/35417668.jpg',
            IsPrimary: false,
            IsVirtualSampleReady: true,
            Attributes: [],
            IsCustom: false,
          },
          {
            IsVisible: true,
            Sequence: 5,
            Id: 38906190,
            Type: 'IM',
            Url: 'https://new-media-asicdn.azureedge.net/images/jpgo/38900000/38906190.jpg',
            IsPrimary: false,
            IsVirtualSampleReady: true,
            Attributes: [818488818],
            IsCustom: false,
          },
          {
            IsVisible: true,
            Sequence: 7,
            Id: 41196398,
            Type: 'IM',
            Url: 'https://new-media-asicdn.azureedge.net/images/jpgo/41190000/41196398.jpg',
            IsPrimary: false,
            IsVirtualSampleReady: true,
            Attributes: [],
            IsCustom: false,
          },
          {
            IsVisible: true,
            Sequence: 8,
            Id: 41196399,
            Type: 'IM',
            Url: 'https://new-media-asicdn.azureedge.net/images/jpgo/41190000/41196399.jpg',
            IsPrimary: false,
            IsVirtualSampleReady: true,
            Attributes: [818488821],
            IsCustom: false,
          },
          {
            IsVisible: true,
            Sequence: 9,
            Id: 41196400,
            Type: 'IM',
            Url: 'https://new-media-asicdn.azureedge.net/images/jpgo/41190000/41196400.jpg',
            IsPrimary: false,
            IsVirtualSampleReady: true,
            Attributes: [],
            IsCustom: false,
          },
          {
            IsVisible: true,
            Sequence: 10,
            Id: 41196446,
            Type: 'IM',
            Url: 'https://new-media-asicdn.azureedge.net/images/jpgo/41190000/41196446.jpg',
            IsPrimary: false,
            IsVirtualSampleReady: true,
            Attributes: [803464858],
            IsCustom: false,
          },
          {
            IsVisible: true,
            Sequence: 11,
            Id: 41196447,
            Type: 'IM',
            Url: 'https://new-media-asicdn.azureedge.net/images/jpgo/41190000/41196447.jpg',
            IsPrimary: false,
            IsVirtualSampleReady: true,
            Attributes: [800989022],
            IsCustom: false,
          },
          {
            IsVisible: true,
            Sequence: 12,
            Id: 92884599,
            Type: 'IM',
            Url: 'https://new-media-asicdn.azureedge.net/images/jpgo/92880000/92884599.jpg',
            IsPrimary: false,
            IsVirtualSampleReady: false,
            Attributes: [],
            IsCustom: false,
          },
        ],
        Attributes: [],
        PriceGrids: [
          {
            IsVisible: true,
            Sequence: 0,
            Id: 840127889,
            Description:
              'WHITE,REFLEX BLUE,LIME GREEN,GRAY,ORANGE,COBALT BLUE,BLACK,RED,HUNTER GREEN,Pad Print',
            Prices: [
              {
                IsVisible: true,
                Sequence: 0,
                Price: 5.27,
                Cost: 3.162,
                DiscountCode: 'R',
                DiscountPercent: 0.4,
                CurrencyCode: 'USD',
                IsUndefined: false,
                Quantity: {
                  From: 72,
                  To: 143,
                },
              },
              {
                IsVisible: true,
                Sequence: 1,
                Price: 5.05,
                Cost: 3.03,
                DiscountCode: 'R',
                DiscountPercent: 0.4,
                CurrencyCode: 'USD',
                IsUndefined: false,
                Quantity: {
                  From: 144,
                  To: 287,
                },
              },
              {
                IsVisible: true,
                Sequence: 2,
                Price: 4.83,
                Cost: 2.898,
                DiscountCode: 'R',
                DiscountPercent: 0.4,
                CurrencyCode: 'USD',
                IsUndefined: false,
                Quantity: {
                  From: 288,
                  To: 575,
                },
              },
              {
                IsVisible: true,
                Sequence: 3,
                Price: 4.61,
                Cost: 2.766,
                DiscountCode: 'R',
                DiscountPercent: 0.4,
                CurrencyCode: 'USD',
                IsUndefined: false,
                Quantity: {
                  From: 576,
                  To: 1007,
                },
              },
              {
                IsVisible: true,
                Sequence: 4,
                Price: 4.39,
                Cost: 2.634,
                DiscountCode: 'R',
                DiscountPercent: 0.4,
                CurrencyCode: 'USD',
                IsUndefined: false,
                Quantity: {
                  From: 1008,
                  To: 2147483647,
                },
              },
            ],
            UsageLevel: 'NONE',
            Attributes: [
              803464858, 858887164, 800989022, 800989021, 858887165, 818488818,
              818488821, 858887166, 858887167, 824804249,
            ],
          },
          {
            IsVisible: true,
            Sequence: 1,
            Id: 848736104,
            Description:
              'WHITE,REFLEX BLUE,LIME GREEN,GRAY,ORANGE,COBALT BLUE,BLACK,RED,HUNTER GREEN,UNIMPRINTED',
            Prices: [
              {
                IsVisible: true,
                Sequence: 0,
                Price: 4.39,
                Cost: 2.634,
                DiscountCode: 'R',
                DiscountPercent: 0.4,
                CurrencyCode: 'USD',
                IsUndefined: false,
                Quantity: {
                  From: 24,
                  To: 2147483647,
                },
              },
            ],
            UsageLevel: 'NONE',
            Attributes: [
              800989037, 858887164, 800989022, 800989021, 858887165, 818488818,
              818488821, 858887166, 858887167, 803464858,
            ],
          },
        ],
        Charges: [],
        Currencies: [
          {
            Symbol: '$',
            DecimalSeparator: '.',
            GroupSeparator: ',',
            Code: 'USD',
            Name: 'US Dollar',
          },
        ],
        LowestPrice: {
          IsVisible: true,
          Sequence: 0,
          Price: 4.39,
          Cost: 2.634,
          DiscountCode: 'R',
          DiscountPercent: 0.4,
          CurrencyCode: 'USD',
          IsUndefined: false,
          Quantity: {
            From: 24,
            To: 2147483647,
          },
        },
        HighestPrice: {
          IsVisible: true,
          Sequence: 0,
          Price: 5.27,
          Cost: 3.162,
          DiscountCode: 'R',
          DiscountPercent: 0.4,
          CurrencyCode: 'USD',
          IsUndefined: false,
          Quantity: {
            From: 72,
            To: 143,
          },
        },
        UpdateDate: '2023-07-11T03:43:47.173Z',
        ExpirationDate: '0001-01-01T05:00:00Z',
        PublishDate: '2023-07-10T23:43:47.27Z',
        SKU: [
          {
            SKU: 'H317P2000',
            Values: [803464858],
          },
          {
            SKU: 'H317P2030',
            Values: [858887167],
          },
          {
            SKU: 'H317P2080',
            Values: [858887166],
          },
          {
            SKU: 'H317P2100',
            Values: [818488821],
          },
          {
            SKU: 'H317P2120',
            Values: [818488818],
          },
          {
            SKU: 'H317P2350',
            Values: [858887165],
          },
          {
            SKU: 'H317P2510',
            Values: [800989021],
          },
          {
            SKU: 'H317P2520',
            Values: [800989022],
          },
          {
            SKU: 'H317P2720',
            Values: [858887164],
          },
        ],
        Settings: {
          ShowProductColors: true,
          ShowProductSizes: true,
          ShowProductShape: true,
          ShowProductMaterial: true,
          ShowProductCPN: true,
          ShowProductImprintMethods: true,
          ShowImprintOptions: false,
          ShowProductPricing: true,
          ShowProductPriceGrids: false,
          ShowProductPriceRanges: true,
          ShowProductAdditionalCharges: true,
          ShowProductDiscount: true,
          ShowProductAlwaysFreeSetup: false,
          ShowProductCanOrderLessThanMinimum: false,
          ShowProductAdditionalOptions: true,
        },
        ImprintColors: 'Standard',
        ImprintLocations:
          'Handle Left - Centered on Back, Handle Right - Centered on Front',
        ImprintSizes: '1.75"H x 2.00"W',
      },
    ],
    Quotes: [],
    CurrencyCode: 'USD',
    CurrencySymbol: '$',
    Currencies: [
      {
        Id: 500059461,
        CurrencyCode: 'CAD',
        ConversionRate: 0.6986,
      },
    ],
    TenantId: 500049971,
    OwnerId: 557456,
    AccessLevel: 'Everyone',
    Access: [
      {
        AccessLevel: 'Everyone',
        EntityId: 0,
        AccessType: 'Read',
      },
    ],
    IsVisible: true,
    IsEditable: true,
    Note: 'Presentation Personal Note',
  } as unknown as Presentation;
};

export const getMockedDistributor = (): Distributor => {
  return {
    Name: 'Distributor Name',
    Email: 'distributor.name@email.com',
    ReplyTo: 'user',
    Phone: '(866) 555-2189',
    Website: '',
    Address: {
      Id: 0,
      Line1: 'UBC Bus Loopx',
      City: 'University Endowment Lands x',
      County: 'Metro Vancouver',
      State: 'BC',
      PostalCode: 'V6T',
      CountryType: 'CA',
      IsPrimary: false,
    } as DistributorAddress,
    Signature: "This's ASI company signature",
    ThirdPartySystemName: 'IZA 2/13',
    ThirdPartyRecordOption: ThirdPartyRecordOption.HideAll,
    DisablePayment: false,
    AllowEditOrderNumber: true,
    AllowLineItemStatuses: true,
    AllowCustomSignature: true,
    AllowReorderTypes: false,
    DefaultSalesperson: 'Creator',
    AllowOrderInquiry: true,
    OrderInquiryOption: 'SpecificUser',
    OrderInquiryUserId: 26190,
    PrimaryBrandColor: '#d42229',
    DisableOrderDiscount: true,
    CompanyId: 601497,
  };
};

export const getMockedDesign = (): DistributorDesign => {
  return {
    ImageUrl: '/assets/images/mock-distributor.png',
    Title: 'Design Name',
    UseNewFormat: false,
    DocumentTypeSettings: [],
  };
};
