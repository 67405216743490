import {
  Directive,
  ElementRef,
  input,
  NgZone,
  output,
  type AfterViewInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { mergeMap, Observable } from 'rxjs';

import { injectDestroyRef } from '@cosmos/util-common';
import { ResizeObserverProvider } from '@cosmos/util-resize-observer-polyfill';

@Directive({
  selector: '[cosOnResize]',
})
export class ResizeObserverDirective implements AfterViewInit {
  readonly resizeObserverOptions = input<ResizeObserverOptions>();

  readonly cosOnResize = output<ResizeObserverEntry>();

  private readonly _destroyRef = injectDestroyRef();

  constructor(
    private readonly _host: ElementRef<HTMLElement>,
    private readonly _resizeObserverProvider: ResizeObserverProvider
  ) {}

  ngAfterViewInit(): void {
    this._observe();
  }

  private _observe(): void {
    if (ngServerMode) {
      return;
    }

    const element = this._host.nativeElement;

    this._resizeObserverProvider
      .provide()
      .pipe(
        mergeMap(
          (ResizeObserver) =>
            new Observable<ResizeObserverEntry>((observer) => {
              const ro = new ResizeObserver((entries) => {
                for (const entry of entries) {
                  if (entry.target === element) {
                    observer.next(entry);
                  }
                }
              });

              ro.observe(element, this.resizeObserverOptions());

              return () => ro.disconnect();
            })
        ),
        takeUntilDestroyed(this._destroyRef)
      )
      .subscribe((entry) => {
        ngDevMode && NgZone.assertNotInAngularZone();
        this.cosOnResize.emit(entry);
      });
  }
}
