<footer class="customer-portal-footer">
  <div class="customer-portal-footer__content">
    @if (state.hasLoaded()) {
      @if (state.footerLogoUrl()) {
        <div
          
          class="customer-portal-footer__logo-col"
        >
          <img [src]="state.footerLogoUrl()!" />
        </div>
      }
      <div class="customer-portal-footer__content-col">
        @if (state.footerCustomText()) {
          <div
            class="whitespace-pre"
            [innerHtml]="
              state.footerCustomText()
                | cosTokenReplacer: contentTokens()
                | trustHtml
            "
          ></div>
        }
      </div>
    } @else {
      <!-- loading state -->
      <div class="customer-portal-footer__logo-col">
        <ngx-skeleton-loader
          class="flex"
          [theme]="{
            height: '4rem',
            width: '9rem',
            margin: '0',
          }"
        />
      </div>
      <div class="customer-portal-footer__content-col">
        <ngx-skeleton-loader
          class="block"
          [theme]="{
            height: '1.5rem',
            width: '15rem',
            margin: '0',
          }"
        />
      </div>
    }
  </div>
</footer>
