import { computed, Injectable } from '@angular/core';
import { RouterState } from '@ngxs/router-plugin';
import { select } from '@ngxs/store';

import { LocalState } from '@cosmos/state';
import { CustomerPortalAuthQueries } from '@customer-portal/auth/data-access-auth';
import {
  CustomerPortalDistributorQueries,
  CustomerPortalPresentationQueries,
} from '@customer-portal/presentations/data-access-presentations';
import {
  CustomerPortalProjectDetailsQueries,
  CustomerPortalProjectQueries,
} from '@customer-portal/projects/data-access/store';

@Injectable()
export class HeaderLocalState extends LocalState<HeaderLocalState> {
  private readonly _routerStateUrl = select(RouterState.url);

  readonly isProductsPage = computed(() => {
    return this._routerStateUrl()?.endsWith('/products');
  });

  readonly userIsCustomer = select(CustomerPortalAuthQueries.getUserIsCustomer);

  readonly project = select(CustomerPortalProjectQueries.getProject);

  readonly presentation = select(
    CustomerPortalPresentationQueries.getPresentation
  );

  readonly template = select(CustomerPortalPresentationQueries.getTemplate);

  readonly cartCount = select(CustomerPortalProjectDetailsQueries.getCartCount);

  readonly quoteCount = select(
    CustomerPortalProjectDetailsQueries.getQuoteCount
  );

  readonly orderCount = select(
    CustomerPortalProjectDetailsQueries.getOrderCount
  );

  readonly invoiceCount = select(
    CustomerPortalProjectDetailsQueries.getInvoiceCount
  );

  readonly proofCount = select(
    CustomerPortalProjectDetailsQueries.getProofCount
  );

  readonly presentationExpired = select(
    CustomerPortalPresentationQueries.getPresentationExpired
  );

  readonly distributor = select(CustomerPortalDistributorQueries.getProfile);

  readonly isReady = computed(() => {
    return (
      this.project() != null &&
      this.presentation() != null &&
      this.template() != null &&
      this.cartCount() != null &&
      this.quoteCount() != null &&
      this.presentationExpired() != null
    );
  });

  readonly logoImageUrl = computed(() => {
    return (
      this.template()?.Logo?.FileUrl ?? this.project()?.Customer.LogoImageUrl
    );
  });
}
