import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  effect,
  inject,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Router, RouterOutlet } from '@angular/router';
import { TeleportOutletDirective } from '@ngneat/overview';
import { Store } from '@ngxs/store';
import { map } from 'rxjs';

import { collectRouteParams, RouteSnapshotService } from '@cosmos/router';
import { assertDefined } from '@cosmos/util-common';
import { CosmosUtilTranslationsModule } from '@cosmos/util-translations';
import { CustomerPortalFooterComponent } from '@customer-portal/common/feature-footer';
import { CustomerPortalHeaderComponent } from '@customer-portal/common/feature-header';
import { CustomerPortalNewFooterComponent } from '@customer-portal/common/feature-new-footer';
import { CustomerPortalNewHeaderComponent } from '@customer-portal/common/feature-new-header';
import { PreviewService } from '@customer-portal/data-access-preview';
import {
  CustomerPortalPresentationQueries,
  selectIsModernTemplate,
} from '@customer-portal/presentations/data-access-presentations';
import { CustomerPortalProjectQueries } from '@customer-portal/projects/data-access/store';

@Component({
  selector: 'customer-portal-projects-host',
  templateUrl: './projects-host.component.html',
  styleUrls: ['./projects-host.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RouterOutlet,
    CosmosUtilTranslationsModule,
    TeleportOutletDirective,
    CustomerPortalHeaderComponent,
    CustomerPortalFooterComponent,
    // template compatibale header/footer
    CustomerPortalNewHeaderComponent,
    CustomerPortalNewFooterComponent,
  ],
  host: {
    '[class.preview-mode]':
      'previewService.isPreviewMode() && !previewService.screenshotMode',
  },
})
export class CustomerPortalProjectsHostComponent {
  private readonly _store = inject(Store);

  readonly previewService = inject(PreviewService);

  readonly isModernTemplate = selectIsModernTemplate();

  readonly routesParam = collectRouteParams(inject(Router)) as Record<
    'projectId' | 'orderId' | 'invoiceId' | 'quoteId',
    string
  >;

  private readonly _document = inject(DOCUMENT);

  // we can't hide the header/footer using media queries since there are pages where we have to hide them on the screen
  vm = toSignal(
    inject(RouteSnapshotService).snapshot$.pipe(
      map((snapshot) => ({
        showHeader: !snapshot?.data?.['hideHeader'],
        showFooter: !snapshot?.data?.['hideFooter'],
        landingMode: !!snapshot?.data?.['landingMode'],
      }))
    ),
    {
      initialValue: {
        showHeader: true,
        showFooter: true,
        landingMode: true,
      },
    }
  );

  constructor() {
    !global_isRealProduction &&
      assertDefined(
        this.routesParam.projectId,
        'CustomerPortalProjectsHostComponent: projectId should be defined on route params'
      );

    this._listenToFavicon();
  }

  private _listenToFavicon() {
    if (!global_isBrowser) {
      return;
    }
    effect(() => {
      const templateIcon = this._store.selectSignal(
        CustomerPortalPresentationQueries.getTemplatePageIcon
      );
      const projectCustomer = this._store.selectSignal(
        CustomerPortalProjectQueries.getProjectCustomer
      );
      const iconUrl =
        templateIcon()?.FileUrl || projectCustomer()?.IconImageUrl;
      if (iconUrl) {
        const link =
          this._document.querySelector<HTMLLinkElement>('#portal-favicon')!;
        link.href = iconUrl;
      }
    });
  }
}
