import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  type OnInit,
} from '@angular/core';
import { Store } from '@ngxs/store';

import { ResizeObserverDirective } from '@cosmos/ui-resize-observer-directive';
import { injectWindow } from '@cosmos/util-common';
import {
  DataBusStateActionMessage,
  IframeDataBus,
  initStoreDispatcher,
} from '@cosmos/util-data-bus';
import { CustomerPortalVisitNotificationsService } from '@customer-portal/common/data-access-visit-notification';
import { CustomerPortalLayoutComponent } from '@customer-portal/common/ui-layout';
import { LookupsActions } from '@esp/lookup/data-access-lookup';
import { SwUpdateService } from '@esp/notifications/feature-notifications-refresh';
import { CustomerPortalIframeEncoreStateActions } from '@esp/presentations/types-customer-portal-iframe-actions';

@Component({
  selector: 'customer-portal-app-root',
  templateUrl: './app-root.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ResizeObserverDirective, CustomerPortalLayoutComponent],
})
export class AppRootComponent implements OnInit {
  private readonly _document = inject(DOCUMENT);
  private readonly _store = inject(Store);
  private readonly _swUpdate = inject(SwUpdateService);
  private readonly _dataBus = new IframeDataBus({
    currentWindow: injectWindow(),
  });

  constructor() {
    //needed to visit tracking
    inject(CustomerPortalVisitNotificationsService);

    this._swUpdate.waitForUpdate();

    initStoreDispatcher(this._dataBus);
  }

  ngOnInit() {
    // TODO(portal-url-migration): remove once we don't support legacy presentations URL.
    if (this._document.location.pathname.startsWith('/projects')) {
      // Should not be loaded if the user navigates to legacy `/presentations` URL,
      // since it's an extra request.
      this._store.dispatch([
        new LookupsActions.Load('Carriers'),
        new LookupsActions.Load('Countries'),
      ]);
    }

    this._dataBus.dispatch(
      new DataBusStateActionMessage(
        new CustomerPortalIframeEncoreStateActions.Init()
      )
    );
  }

  onResize(event: ResizeObserverEntry): void {
    this._dataBus.dispatch(
      new DataBusStateActionMessage(
        new CustomerPortalIframeEncoreStateActions.HeightChanged(
          event.contentRect.height
        )
      )
    );
  }
}
