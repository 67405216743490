import { inject } from '@angular/core';
import type { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { Store } from '@ngxs/store';
import { catchError, EMPTY } from 'rxjs';

import { getParamsObject } from '@cosmos/core';
import { PreviewService } from '@customer-portal/data-access-preview';
import {
  CustomerPortalDistributorActions,
  CustomerPortalPresentationActions,
  CustomerPortalPresentationQueries,
} from '@customer-portal/presentations/data-access-presentations';
import {
  CustomerPortalProjectActions,
  CustomerPortalProjectDetailsActions,
  CustomerPortalProjectDetailsQueries,
  CustomerPortalProjectQueries,
} from '@customer-portal/projects/data-access/store';

export const resolveProjectDetails: ResolveFn<void> = (
  route: ActivatedRouteSnapshot
) => {
  const store = inject(Store);
  const { projectId } = getParamsObject(route.paramMap, {
    required: ['projectId'],
  })!;
  const previewService = inject(PreviewService);

  // in mocked enteties mode we skip data loading
  if (previewService.useMockProjectAndPresentation) {
    const project = store.selectSnapshot(
      CustomerPortalProjectQueries.getProject
    );
    if (!!project && project.Id! === parseInt(projectId, 10)) {
      return;
    }
  }

  // Note: do not return the result of this observable since it shouldn't
  // block the UI from loading; this should act as fire & forget; hereby,
  // we're able to show skeleton loaders.

  // fire project loading
  store.dispatch(new CustomerPortalProjectActions.GetProject(projectId));

  // fire project details loading
  store
    .dispatch(
      new CustomerPortalProjectDetailsActions.GetProjectDetails(projectId)
    )
    .pipe(catchError(() => EMPTY))
    .subscribe(() => {
      // We first need to get the project details since it has the `PresentationId`.
      const presentationId = store.selectSnapshot(
        CustomerPortalProjectDetailsQueries.getPresentationId
      );
      const presentation = store.selectSnapshot(
        CustomerPortalPresentationQueries.getPresentation
      );
      const actions: Array<object> = [
        new CustomerPortalDistributorActions.GetDistributor(presentationId),
        new CustomerPortalDistributorActions.GetDesign(presentationId),
      ];
      const settingsParam = route.queryParamMap.get('settings');
      // at this moment we already might have presentation loaded, check this /libs/customer-portal/auth/data-access-customer-portal-access-guard/src/lib/legacy-url-redirect-handler.ts#L90
      // try to avoid loading it twice
      if (presentation?.Id !== presentationId) {
        actions.push(
          new CustomerPortalPresentationActions.GetPresentation({
            presentationId,
            shouldLoadQuote: true,
            shouldLoadTemplate: true,
            settings: settingsParam,
          })
        );
      } else {
        // otherwise, load the pending quote and apply optional settings if needed
        // (dt): can we postpone quote loading until the user navigates to the quote page?
        actions.push(
          new CustomerPortalPresentationActions.GetPendingQuote(presentationId)
        );
        if (settingsParam) {
          actions.push(
            new CustomerPortalPresentationActions.ApplyPresentationSettingsParams(
              settingsParam
            )
          );
        }
      }
      store.dispatch(actions);
    });
};
