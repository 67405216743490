import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
} from '@angular/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import {
  TokenReplacerPipe,
  type ContentToken,
} from '@cosmos/ui-tokenized-input';
import { TrustHtmlPipe } from '@cosmos/util-html-pipes';
import { createContentTokens } from '@customer-portal/common/util-tokens';

import { FooterLocalState } from './footer.local-state';

@Component({
  selector: 'customer-portal-new-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FooterLocalState],
  imports: [NgxSkeletonLoaderModule, TokenReplacerPipe, TrustHtmlPipe],
})
export class CustomerPortalNewFooterComponent {
  readonly state = inject(FooterLocalState);

  readonly contentTokens = computed<ContentToken[]>(() => {
    return createContentTokens({
      project: this.state.project(),
      distributor: this.state.distributor(),
    });
  });
}
