import type { ContactType, Entity, Shareable } from '@cosmos/types-common';
import type { Address } from '@cosmos/types-party';
import type { PartyView } from '@esp/common/types';

import type { Customer } from './customer';

export interface BaseProject extends Entity, Shareable {
  Contacts?: Customer[];
  Budget?: number | null;
  CreateDate?: Date;
  Customer: Customer;
  Description?: string;
  InHandsDate?: Date;
  IsActive?: boolean;
  IsInHandsDateFlexible?: boolean;
  ProjectEmail?: string;
  Resolution?: string;
  CurrentStatusStep?: ProjectStatusStep;
}

export interface Project extends BaseProject {
  EventType?: string;
  EventDate?: Date;
  StatusStep?: ProjectStatusStep;
  Statuses?: ProjectStatus[];
  StatusName?: ProjectStepName;
  CurrencyTypeCode?: string;
  NumberOfAssignees?: number | null;
  ProjectEmail?: string;
  AcknowledgementContact?: ProjectContactItem;
  BillingContact?: ProjectContactItem;
  ShippingContact?: ProjectContactItem;
  WebsiteId?: string;
}

export interface ProjectContactItem {
  Id?: number;
  Address?: Address;
  CompanyName?: string;
  EmailAddress: string;
  Name: string;
  Type?: ContactType;
  CountryType?: ContactType;
  Company?: PartyView;
  Links?: any[];
}

export interface PerformanceMetrics {
  OrdersCount: number;
  OrdersLastWeekCount: number;
  OrdersThisWeekCount: number;
  TotalRevenue: number;
  TotalCost: number;
  Margin: number;
  MarginPercent: number;
  LaunchDate: string;
  CloseDate: string;
}

export interface FundraisingProgressMetrics {
  Value: string;
  Units: string;
}

export interface ProjectStatus {
  CreateDate: string;
  Name: string;
  Step: number;
}

export enum ProjectStatusStep {
  NegotiatingAndPitching = 0,
  ProcessingAndFulfillment = 1,
  Closed = 2,
}

export const projectStepNameKeys: Record<string, string> = {
  NegotiatingAndPitching:
    'espActivities.esp-activities-feature-activities.project-activity-page.negotiating-and-pitching',
  ProcessingAndFulfillment:
    'espActivities.esp-activities-feature-activities.project-activity-page.processing-and-fulfillment',
  Closed:
    'espActivities.esp-activities-feature-activities.project-activity-page.closed',
};

export enum ProjectStepName {
  NegotiatingAndPitching = 'Negotiating and Pitching',
  ProcessingAndFulfillment = 'Processing and Fulfillment',
  Closed = 'Closed',
}

// This is used for displaying status in a format that is required by client. Api returns value with `and`, but status should be displayed with `&`
export enum ProjectStepDisplayValue {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'Negotiating and Pitching' = 'Negotiating & Pitching',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'Processing & Fulfillment' = 'Processing & Fulfillment',
  Closed = 'Closed',
}
